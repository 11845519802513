<template lang="pug">
    v-container(fluid) 
        v-row
            v-col(cols="12")
                v-row(align="center" justify="center" style="height: 300px;")
                    v-card.ma-3.pa-6(flat)
                        v-card-title Вход
                        v-form(v-on:submit.prevent="onSubmit")
                            v-text-field(v-model="username" name="username" :error-messages="emailErrors" label="Логин" required @input="$v.username.$touch()" @blur="$v.username.$touch()")
                            v-text-field(v-model="password" :type="'password'" name="password" :error-messages="passwordErrors" label="Пароль" required @input="$v.password.$touch()" @blur="$v.password.$touch()")
                            
                            v-btn(type="submit") Вход

        v-dialog(v-model="dialog" max-width="290")
            v-card
                v-card-text {{ errorMessage }}

</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";

    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    export default {
        name: 'registration',
        mixins: [validationMixin],
        validations: {
            username: { required },
            password: { required }
        },

        data: () => ({
            username: '',
            password: '',
            errorMessage: '',
            dialog:false
        }),

        created() {

        },

        mounted () {
            
        },

        computed: {
            ...mapState('authentication',['user','grants']),
            emailErrors () {
                const errors = []
                if (!this.$v.username.$dirty) return errors
                !this.$v.username.required && errors.push('E-mail обязательное поле.')
                return errors
            },
            passwordErrors () {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.required && errors.push('Пароль обязательное поле.')
                return errors
            }
        },

        methods: {
            ...mapActions("authentication", ["login","getUser"]),
            ...mapMutations("authentication", ["setGrants"]),
            onSubmit() {
                this.errorMessage = '';
                let udata = {username:this.username,password:this.password}
                this.$v.$touch()
                if (!this.$v.$invalid){
                    this.$nextTick(() => {
                        this.login(udata).then(data => {
                            if (data) {
                                localStorage.setItem("access_token", data.access_token)
                                localStorage.setItem("LoginName", this.username)
                                localStorage.setItem('grants', data.grants)

                                // let grants = JSON.parse(data.grants)
                                
                                // this.getUser()
                                // this.setGrants(grants)
                                // this.$router.push('/report/control')
                                location.href = '/report/control'
                            } else {
                                this.dialog = true
                                this.errorMessage = 'Такой пользователь не зарегистрирован'
                            }
                        })
                        .catch(error => {
                            this.dialog = true
                            this.errorMessage = error;
                        })
                    })
                }
            }
        }
    }
</script>